<template>
  <div id="AboutUs">
    <div class="banner">
      <img src="../../assets/imgs/yuhang/aboutBanner.png" />
    </div>
    <div class="introd">
      <h2>杭州市余杭区人工智能教育</h2>
      <p>杭州市余杭区人工智能教育是杭州市余杭区教育局为贯彻落实国务院新一代人工智能发展规划，抢抓人工智能发展的重大战略机遇，推动人工智能机器人教育技术的发展和创新打造的科教类省重点项目。</p>
      <p>项目依托“标准校实验室+中心校实验室+基地实验室”三级体系，以杭州市余杭区人工智能教育皇国山基地与杭州市余杭区人工智能教育瓶窑中学基地两大基地校为核心，辐射4大中心校，助推96所中小学普及人工智能教育，致力于打造余杭区人工智能教育品牌，为“大众创业，万众创新”国家战略培养创新型人才。</p>
    </div>
    <div class="banner">
      <img src="../../assets/imgs/yuhang/aboutBanner2.png" />
    </div>
    <div class="introd" style="margin-top:20px;">
      <h2>创造一流的AI科创学习环境</h2>
      <p>杭州市余杭区人工智能教育皇国山基地于2020年4月建成，坐落在皇国山市民公园内，基地建筑面积约8800平方米，在设计上，以互动性、实用性、启发性、价值感为核心理念。基地内建有人工智能专用教室、创客工坊、演讲厅、竞赛厅、会议室、直播室等，是集互动体验、拓展提升、赛事活动、展示交流为一体的教育示范点，可容纳450名学生同时开课。</p>
      <p>杭州市余杭区人工智能教育瓶窑中学基地坐落于杭州市余杭区瓶窑中学内，建筑面积1000平方米，以“AI科普+AI科学+AI科创”为主题，共开辟8间教学实验室，可同时容纳240名学生开课，为余杭西部地区的中小学生提供了一个集互动体验、拓展提升、赛事活动、交流展示为一体的教育平台，并定期举办各类人工智能主题活动。</p>
    </div>
    <div class="banner">
      <!-- <img  src="../../assets/imgs/yuhang/aboutBanner3.png" /> -->
      <ul>
        <img src="../../assets/imgs/yuhang/aboutBanner4.png" />
        <p>在两大基地，同学们可以学习AI知识、创造AI作品、参与AI竞赛，从AI视角了解世界正在发生的变化，思考如何运用AI解决现实世界的问题，尝试去改变和创造未来的世界。</p>
      </ul>
    </div>
    <div class="content">
      <ul>
        <li>
          <h2>打造高品质AI精品课程</h2>
          <p>
            两所基地围绕着生命科学、人文社会、智慧生活、宇宙探索和地球公民五大科创主题，衍生出25门人工智能PBL课程，深刻而全面地向同学们展示了人工智能技术与日常生活之间的奇妙联结，让学生在做项目的过程中学习科创知识技能，并且期望学生能在学习过程中，关注课程主题背后的宏观视野。
            <br />
            <br />25门PBL系列课程的愿景，是希望学生完成学习以后，能够有信心，望眼宇宙并思考未来的宇宙探索；有责任心，做一个关心人类命运共同体的地球公民；有爱心，了解生物和生命健康；有能力，面向社会并参与构筑智慧生活和人文社会。
          </p>
        </li>
        <li>
          <img src="../../assets/imgs/yuhang/pic_1.png" />
        </li>
        <li style="clear:both;">
          <img src="../../assets/imgs/yuhang/pic_5.png" width="450" />
        </li>
        <li style="margin-top: 70px;">
          <h2>组织丰富精彩的AI活动</h2>
          <p
            style="width:100%;"
          >杭州市余杭区人工智能教育为杭州市余杭区学生量身定制了丰富多彩的科创活动，例如：“了不起的同学”；夏、冬令营活动；引擎演讲等。别出心裁的展现形式，为余杭区学生增强科创知识的储备，引发学生科创兴趣，为培养孩子科技特长提供了成长沃土。</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
#AboutUs {
  width: 100%;
  height: auto;
  overflow-y: hidden;
  padding-bottom: 88px;
  min-width: 1200px;
  /* padding-top: 77px; */
}
#AboutUs .banner {
  width: 100%;
  height: auto;
}
#AboutUs .banner > img {
  width: 100%;
}
#AboutUs .banner > ul {
  width: 100%;
}
#AboutUs .banner > ul > img {
  width: 100%;
}
#AboutUs .banner > ul > p {
  text-align: center;
  line-height: 50px;
  font-size: 16px;
  color: #606978;
}
#AboutUs .banner > ul > li {
  /* width: 33%; */
  float: left;
  text-align: center;
  font-size: 16px;
  color: #1c222e;
}
.introd {
  width: 1200px;
  margin: 0 auto;
  margin-top: 60px;
  position: relative;
  margin-bottom: 40px;
}
.introd > h2 {
  text-align: center;
  font-size: 32px;
  color: #333a48;
  font-weight: 500;
  padding-bottom: 10px;
}
.introd > h2::before {
  content: "";
  position: absolute;
  width: 7px;
  height: 28px;
  background: rgba(93, 90, 237, 1);
  border-radius: 4px;
  margin-top: 13px;
  margin-left: -15px;
}
.introd > p {
  font-size: 16px;
  color: #606978;
  line-height: 30px;
  margin-top: 20px;
}
.content {
  width: 1200px;
  margin: 0 auto;
  height: auto;
  overflow: hidden;
  margin-top: 60px;
}
.content > ul > li > h2 {
  padding-left: 16px;
  font-size: 32px;
  color: #333a48;
  font-weight: 500;
  margin-bottom: 30px;
}
.content > ul > li > h2::before {
  content: "";
  position: absolute;
  width: 7px;
  height: 28px;
  background: rgba(93, 90, 237, 1);
  border-radius: 4px;
  margin-top: 3px;
  margin-left: -15px;
}
.content > ul {
  width: 1200px;
  height: auto;
  overflow: hidden;
}
.content > ul > li {
  float: left;
  width: 50%;
  position: relative;
  font-size: 16px;
  color: #606978;
  line-height: 30px;
}
.content > ul > li > p {
  width: 86%;
}
</style>